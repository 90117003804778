<template>
        <span class="table-column-row">
            <span class="min">{{sensor.min !== '' ? validData(sensor.min) : ''}}</span>
            <span v-if="sensor.min !== ''" class="slash">/</span>
            <span class="middle">{{sensor.avg !== '' ? sensor.avg.toFixed(1).replace(/\./g, ',')  : ''}}</span>
            <span v-if="sensor.avg !== ''" class="slash">/</span>
            <span class="max">{{sensor.max !== '' ? validData(sensor.max) : ''}}</span>
            <img  v-if="+sensor.total_for_sensor < 96 && +sensor.total_for_sensor > 0 && date !== formatDate()" src="/images/icon_status_20_20/error.png" alt="Error" class="table-column-image">
        </span>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from 'moment';

export default {
  name: 'SelectedDeviceTableRow',
  data() {
    return {}
  },
  props: ['sensor', 'date'],
  methods: {
    formatDate() {
      return moment().format('DD.MM.YYYY');
    },
    validData(data) {
      if(Number.isInteger(data)) {
        return data.toFixed(1).replace(/\./g, ',');
      } else {
        const num = +(Math.round(data + "e+1")  + "e-1")
        return Number.isInteger(num) ? String(num.toFixed(1)).replace(/\./g, ',')  : String(num).replace(/\./g, ',') ;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.table-column-row { 
    line-height: 22px;

    .slash {
        margin-left: 5px;
        margin-right: 5px;
    }

    .min {
        color: #1255C8;
    }

    .max {
        color: #A73131;
    }

    .middle {
        color: #022259;
    }

    .table-column-image {
        width: 17px;
        height: 17px;
        margin-left: 15px;
        margin-bottom: 5px;
    }
}
</style>

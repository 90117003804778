<template>
  <div class="device-container">
    <div class="device-heading">
      <div class="device-title">
        <img @click="goBack()" src="/images/back_button.png" alt="Back" class="device-title-button">
        <span>PAUL ONE {{deviceDetails.uid}}</span>
      </div>
      <div class="filter-container">
        <div class="device-filter">
          <DatePicker v-model="searchDate" locale="de" placeholder="Datum">
              <template v-slot="{ inputValue, inputEvents }" class="datepicker">
                <div class="container" :value="inputValue"
                  v-on="inputEvents">
                  <input
                  type="search"
                  id="date"
                  class="search-input"
                  :class="{ 'border-red-600': errorMessage }"
                  :value="inputValue"
                  placeholder="Datum"
                  @input="filterByDate()"
                  >
                </div>
              </template>
            </DatePicker>
          </div>
      </div>
    </div>
    <div class="showDetails" :style="{'height': '73vh','grid-column-gap': '26px','display': 'grid','grid-template-columns': showDetails? '25% 73.7%' : '1% 97.7%'}">
      <div class="details-panel" :style="{'transition': '0.3s','transform': showDetails? 'translateX(0%)' : 'translateX(-100%)'}" :class="{'unactive-details': !showDetails}">
        <img @click="showDetails = !showDetails" src="/images/button_right.png" alt="ButtonRight" class="details-head-button" style="cursor: pointer">
        <div class="details-head">
          <span class="details-title">Allgemeine Informationen</span>
        </div>
        <div class="details-body">
          <div class="details-item">
            <span class="title">Seriennummer:</span>
            <span class="item">{{deviceDetails.uid}}</span>
          </div>
          <!-- <div class="details-item">
            <span class="title">Adresse:</span>
            <span class="item">{{deviceDetails.address}}</span>
          </div> -->
          <div class="details-item">
            <span class="title">Inbetriebnahme:</span>
            <span class="item">{{formatDate(deviceDetails.started_at)}}</span>
          </div>
          <div class="details-item">
            <span class="title">Letzter Neustart:</span>
            <span class="item">{{formatDate(deviceDetails.restarted_at)}}</span>
          </div>
        </div>
      </div>
      <div class="device-datatable" style="overflow-x:auto;">
        <div class="device-table">
          <div class="table-head">
            <div class="table-row">
              <div class="table-column table-head-column-date">
                <div class="column">
                  <div class="column-title">Datum</div>
                </div>
              </div>
              <div class="table-column" v-for="sensor in getSensors" :key="sensor">
                <div class="column">
                  <div v-if="sensor.sensors_id.length > 1 && sensor.name != 'Strang'" class="column-title">{{ `${sensor.name}` }} (&deg;C){{ `(${sensor.sensors_id.length} Sensoren)` }}</div>
                  <div v-else-if="sensor.sensors_id.length > 1 && sensor.name == 'Strang'" class="column-title">Stränge (&deg;C){{ `(${sensor.sensors_id.length} Sensoren)` }}</div>
                  <div v-else class="column-title">{{ sensor.name }} (&deg;C)</div>
                </div>
              </div>
            </div>
          </div>
          <spinner v-if="load" class="spinner"></spinner>
          <div class="table-body">
            <div class="table-row" @click="selectedDate(Object.keys(measurement)[0])" v-for="(measurement, index) in deviceData" :key="index">
                <span class="table-column-date">{{Object.keys(measurement)[0]}}</span>
                <table-row  class="table-column" :sensor="Object.values(measurement)[0][0]" :date="Object.keys(measurement)[0]"></table-row>
                <table-row  class="table-column" :sensor="Object.values(measurement)[0][1]" :date="Object.keys(measurement)[0]"></table-row>
                <table-row  class="table-column" :sensor="Object.values(measurement)[0][2]" :date="Object.keys(measurement)[0]"></table-row>
                <table-row  class="table-column" :sensor="Object.values(measurement)[0][3]" :date="Object.keys(measurement)[0]"></table-row>
                <table-row  class="table-column" :sensor="Object.values(measurement)[0][4]" :date="Object.keys(measurement)[0]"></table-row>
                <table-row  class="table-column" :sensor="Object.values(measurement)[0][5]" :date="Object.keys(measurement)[0]"></table-row>
                <table-row  class="table-column" :sensor="Object.values(measurement)[0][6]" :date="Object.keys(measurement)[0]"></table-row>
                <table-row  class="table-column" :sensor="Object.values(measurement)[0][7]" :date="Object.keys(measurement)[0]"></table-row>
                <table-row  class="table-column" :sensor="Object.values(measurement)[0][8]" :date="Object.keys(measurement)[0]"></table-row>
                <table-row  class="table-column" :sensor="Object.values(measurement)[0][9]" :date="Object.keys(measurement)[0]"></table-row>
                <table-row  class="table-column" :sensor="Object.values(measurement)[0][10]" :date="Object.keys(measurement)[0]"></table-row>
                <table-row  class="table-column" :sensor="Object.values(measurement)[0][11]" :date="Object.keys(measurement)[0]"></table-row>
                <table-row  class="table-column" :sensor="Object.values(measurement)[0][12]" :date="Object.keys(measurement)[0]"></table-row>
                <table-row  class="table-column" :sensor="Object.values(measurement)[0][13]" :date="Object.keys(measurement)[0]"></table-row>
                <table-row  class="table-column" :sensor="Object.values(measurement)[0][14]" :date="Object.keys(measurement)[0]"></table-row>
                <table-row  class="table-column" :sensor="Object.values(measurement)[0][15]" :date="Object.keys(measurement)[0]"></table-row>
                <table-row  class="table-column" :sensor="Object.values(measurement)[0][16]" :date="Object.keys(measurement)[0]"></table-row>
                <table-row  class="table-column" :sensor="Object.values(measurement)[0][17]" :date="Object.keys(measurement)[0]"></table-row>
                <table-row  class="table-column" :sensor="Object.values(measurement)[0][18]" :date="Object.keys(measurement)[0]"></table-row>
                <table-row  class="table-column" :sensor="Object.values(measurement)[0][19]" :date="Object.keys(measurement)[0]"></table-row>
                <table-row  class="table-column" :sensor="Object.values(measurement)[0][20]" :date="Object.keys(measurement)[0]"></table-row>
                <table-row  class="table-column" :sensor="Object.values(measurement)[0][21]" :date="Object.keys(measurement)[0]"></table-row>
                <table-row  class="table-column" :sensor="Object.values(measurement)[0][22]" :date="Object.keys(measurement)[0]"></table-row>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TableRow from "../../components/OutputSensors0nSelectedDevicePage.vue";
import Spinner from "../../components/Spinner.vue";
import * as _ from 'lodash';
import { mapGetters, mapActions, mapMutations } from "vuex";
import moment from 'moment';
import { isEmpty } from 'lodash';
import { DatePicker } from 'v-calendar';

export default {
  name: 'SelectedDevice',
  components: {
    DatePicker,
    TableRow,
    Spinner,
  },
  data() {
    return {
      columns: [
        { text: 'Kaltwasser'},
        { text: 'Speicherladung Vorlauf '},
        { text: 'Speicherladung Rücklauf'},
        { text: 'Speicher Vorlauf'},
        { text: 'Speicher Rücklauf'},
        { text: 'Strang'},
        { text: 'Heizung Vorlauf'},
        { text: 'Heizung Rücklauf'},
      ],
      deviceDetails: null,
      searchDate: null,
      loading: false,
      load: false,
      showDetails: true,
      showError: true,
      deviceData: [],
      payload: {
        id: null,
        search: {
          offset: 0
        }
      }
    }
  },
  watch: {
    searchDate: function(val) {
      if(val) {
        this.filterByDate();
      } else if(this.searchDate == null) {
        this.loading = true;
        delete this.payload.search.date
        this.filterByDate();
      }
    },
  },
  mounted() {
    const listElm = document.querySelector('.device-datatable');
    listElm.addEventListener('scroll', e => {
      if(listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight && this.deviceData.length > 15) {
        this.payload.search.offset += 100;
        console.log(this.payload.search.offset);
        this.load = true;
        if(this.payload.id == null) {
          const url = window.location.href.split('/');
          this.payload.id = url[5]
        }
        this.getMeasurementsByDevice(this.payload).then(() => {
          this.changeStructureObject(this.getMeasurementsByDeviceData.measurements)
          this.load = false;
        })
      }
    })
  },
  computed: {
    ...mapGetters(['getSelectedData', 'getMeasurementsByDeviceData', 'getSelectedDateData', 'getSensors', 'getHashData', 'getOffset']),
    tableHead() {
      return this.columns;
    },
  },
  created() {
    this.deviceDetails = JSON.parse(localStorage.getItem('deviceDetails'));
    this.getSensorsConfig().then(() => {
      if(this.getHashData != null) {
        this.deviceData = this.getHashData;
        this.payload.search.offset = this.getOffset;
        console.log(this.payload.search.offset)
        this.saveHashData(null);
        this.saveOffset(0);
      } else {
        this.getSelectedDeviceData();
        this.load = true;
      }
    })
  },
  methods: {
    ...mapActions(['getSelectedDateDevice', 'getMeasurementsByDevice', 'getSelectedDevice', 'getSensorsConfig']),
    ...mapMutations(['saveHashData', 'saveOffset']),
    formatDate(data) {
      if(data) {
        return moment(data).format('DD.MM.YYYY');
      } else {
        return '';
      }
    },
    filterByDate() {
      if(this.searchDate) {
        this.payload.search.date = moment(this.searchDate).format('YYYY-MM-DD');
      }
      this.deviceData = [];
      this.load = true;
      this.getSelectedDeviceData();
    },
    selectedDate(date) {
      let newDate = date.split('.').reverse().join('-');
      let payload = {
        id: this.deviceDetails._id,
        date: newDate,
        search: {
          offset: 0
        }
      }
      this.saveHashData(this.deviceData);
      this.saveOffset(this.payload.search.offset);
      this.$router.push(`/devices/${this.deviceDetails._id}/${newDate}`)
      this.getSelectedDateDevice(payload);
    },
    goBack() {
      this.$router.back();
    },
    changeStructureObject(data) {
      if(data) {
        for(const measurement of data) {
          const dataPerDay = [];
          let date = null;
          date = measurement._id.split('.').reverse().join('.');
          for(let i = 0; i < this.getSensors.length; i++) {
            const max = [];
            const min = [];
            const avg = [];
            const sensors = [];
            const total_for_sensor = [];
            for(const sens of this.getSensors[i].sensors_id) {
              for(const sensor of measurement.sensors) {
                if( sens == sensor.sensor_id) {
                  max.push(sensor.max);
                  min.push(sensor.min);
                  avg.push(sensor.avg);
                  sensors.push(sensor.sensor_id);
                  total_for_sensor.push(sensor.total_for_sensor);
                }
              }
            }
            
            dataPerDay.push({
              sensor_id: sensors.sort((a, b) => a - b),
              max: max.length > 0 ? Math.max(...max) : '',
              min: min.length > 0 ? Math.min(...min) : '',
              avg: avg.length > 0 ? _.mean(avg) : '',
              total_for_sensor: total_for_sensor.length > 0 ? Math.min(...total_for_sensor) : '',
            });
          }
          const obj = {};
          obj[date] = dataPerDay
          this.deviceData.push(obj);
        } 
        console.log("DeviceData",this.deviceData)
      }
    },
    getSelectedDeviceData() {
      const url = window.location.href.split('/');
      this.deviceDetails = JSON.parse(localStorage.getItem('deviceDetails'));
      this.payload.id = url[5]
      this.getMeasurementsByDevice(this.payload).then(() => {
        console.log(this.getMeasurementsByDeviceData.measurements)
        this.changeStructureObject(this.getMeasurementsByDeviceData.measurements);
        this.load = false;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.spinner {
  left: 60%;
  top: 60%;
}
.device-container {
  font-family: 'Roboto', sans-serif;
  padding-top: 45px;
  margin-left: 45px;
  margin-right: 45px;
  margin-bottom: 43px;

  .device-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .device-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: auto;
      font-weight: 500;
      font-size: 36px;
      color: #022259;
      text-align: left;
      font-style: normal;

      .device-title-button {
        width: 15px;
        height: 27px;
        margin-right: 20px;
        cursor: pointer;
      }
    }
  }

  .device-table {
    width: 100%;

    .table-head, .table-body {
      width: 100%;

      .table-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 50px;

        .column {
          margin-left: 10px;
        }
      }
    }

    .table-head {
      font-weight: 500;
      font-size: 18px;
      color: #022259;
      border: 2px solid #EFF9FF;
      height: 50px;
      position: sticky; 
      z-index: 9;
      top: 0;
      background: #ffffff;

      .table-row {

        .table-head-column-date {
          width: 200px;
          position: sticky;
          left: 0;
          background: #ffffff;
        }

        .table-column {
          width: 200px;

          .column {
            padding: 10px;
          }
        }
      }
    }

    .table-body {
      font-size: 18px;
      font-weight: 400;
      color: #022259;
      width: 100%;
      border: 2px solid #EFF9FF;

      .table-row {
        min-height: 60px;
        background: #ffffff;
        cursor: pointer;

        &:nth-child(odd) {
          background: #EFF9FF;
        }

        .table-column-date {
          width: 180px;
          position: sticky;
          z-index: 2;
          left: 0;
          padding-left: 20px;
          line-height: 22px;
          &:nth-child(odd) {
          background: inherit;
          }
        }

        .table-column {
          width: 200px;
          text-align: center;
          line-height: 22px;
        }
      }

      .table-row:hover {
        background: #e2f5ff;
      }
    }
  }
  
  .device-datatable {
    display: grid;
    width: 99%;
    margin-top: 35px;
    border-collapse: collapse;
    border-spacing: 0;
  }
}

.container {
      position: relative;
      height: 45px;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 16px;
      margin: auto 0;
      width: 16px;
      height: 16px;
      background: url('/images/calendar.png') no-repeat;
      background-size: cover;
    }
    input {
      width: 100%;
    }
} 

.device-filter {
      width: 235px;
      height: 45px;
      cursor: pointer;

      input::-webkit-input-placeholder { color: #022259; }

      .search-input {
      font-size: 18px;
      color: #022259;
      background: #FFFFFF;
      border: 2px solid #022259;
      border-radius: 13px;
      box-sizing: border-box;
      padding: 14px;
      width: 100%;
      height: 100%;
    }

    input[type=text] {
      padding-left: 50px;
    }
}

.unactive-details {
  position: absolute;
  left: 20px;
  border-left: 2px solid #EFF9FF;
  height: 100%;


  .details-head {
    display: none !important;

    .details-title {
      display: none;
    }
  }

  .details-body {
    display: none;
  }

  .details-head-button {
    transform: none !important;
  }
}

.details-panel {
  margin-top: 35px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  transition: 0.5s;
  transform: translateX(0%);

    .details-head {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 18px;
      color: #022259;
      border: 2px solid #EFF9FF;
      height: 56px;

      .details-title {
        padding-left: 10px;
      }

      .icon-exit {
        padding-right: 10px;
        width: 16px;
        height: 16px;
      }
    }

    .details-head-button {
        position: absolute;
        z-index: 2;
        width: 30px;
        height: 30px;
        right: -15px;
        top: 35px;  
        transform: scale(-1, 1);
    }

    .details-body {
      background: #EFF9FF;
      height: 100%;
      color: #022259;
      border: 2px solid #EFF9FF;
      font-size: 18px;
      padding: 10px;

      .warning-buttons {

          width:100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          button {
            width: 100px;
            background-color: #022259;
            border: 2px solid #022259;
            border-radius: 5px;
            color: #ffffff;
            cursor:pointer;
          }

          button:hover {
            background-color: #033181;
            border: 2px solid #033181;
          }
      }

      .details-item {
        padding-bottom: 35px;

        .title {
          font-weight: 500;
          font-size: 18px;
        }

        .item {
          padding-left: 10px;
        }
      }

      .details-item:last-child {
        padding-bottom: 0px;
      }
    }
  }

input[type="search"]::-webkit-input-placeholder {
    color: #afafaf;
}

input[type="search"]::-moz-placeholder {
    color: #afafaf;
}

@media screen and (min-width: 1400px) and (max-width: 1920px){
  template {
    width: 100%;

    .device-container {
      width: 100%;
      padding-left: 45px;
    }
  }
}

@media screen and (max-width: 1400px){
  .device-heading {
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 2010px){
  .device-heading {
    width: 1920px;
    margin: 0 auto;
  }

  .device-container {
    width: 1920px !important;
    margin: 0 auto !important;
  }

  .device-table {
    width: 100%;
  }
}
</style>
